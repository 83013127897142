<template>
  <div class="GalleryGroup" v-if="item && item.items">
    <GalleryFile v-for="(item, index) in item.items" :key="`${index}${item.id}`" :item="item" @click="$emit('click', $event)" active="button" />
  </div>
</template>

<script>
export default {
  props: ["item"],
  components: {
    GalleryFile: () => import("./GalleryFile.vue"),
  },
};
</script>
